import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 716.000000 733.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,733.000000) scale(0.100000,-0.100000)">

<path d="M0 7305 l0 -25 3580 0 3580 0 0 25 0 25 -3580 0 -3580 0 0 -25z"/>
<path d="M2865 6116 c-151 -22 -302 -69 -427 -132 -69 -35 -223 -131 -250
-156 -7 -7 -44 -40 -82 -72 -197 -171 -366 -467 -428 -751 -19 -84 -22 -131
-22 -295 0 -166 4 -209 23 -290 52 -221 126 -384 253 -558 48 -65 204 -224
263 -268 22 -16 42 -31 45 -34 16 -18 157 -99 234 -135 179 -83 379 -125 591
-125 312 1 579 89 837 278 87 63 242 217 288 287 31 47 27 44 -62 -41 -282
-272 -571 -394 -932 -394 -473 0 -908 263 -1133 686 -158 298 -189 688 -80
1009 129 376 433 680 806 805 151 51 239 64 416 64 128 0 179 -4 255 -22 188
-42 333 -106 483 -210 76 -52 236 -202 270 -252 9 -13 17 -20 17 -14 0 15 -84
125 -149 196 -56 61 -194 174 -276 225 -110 69 -265 134 -402 170 -122 31
-156 35 -293 39 -97 2 -189 -2 -245 -10z"/>
<path d="M3175 5869 c-201 -15 -367 -73 -556 -195 -71 -45 -232 -191 -251
-229 -12 -22 -21 -28 108 78 365 299 889 327 1288 70 117 -75 219 -177 307
-306 l61 -88 105 3 c62 2 108 7 110 13 4 14 -67 133 -123 205 -49 65 -135 152
-184 189 -19 14 -37 29 -40 32 -3 3 -41 28 -85 56 -89 55 -233 116 -340 145
-63 16 -106 21 -265 31 -25 2 -86 0 -135 -4z"/>
<path d="M2795 5043 c-87 -103 -115 -138 -115 -145 0 -4 136 -8 303 -8 296 0
376 -7 378 -33 2 -23 -3 -42 -14 -49 -7 -4 -158 -8 -338 -8 -239 0 -328 -3
-337 -12 -9 -9 -12 -70 -12 -214 0 -165 3 -204 15 -214 11 -9 98 -11 357 -8
331 4 345 5 403 27 74 29 143 83 161 127 22 53 18 116 -11 158 -30 44 -30 41
-6 74 68 96 31 211 -88 275 -85 47 -160 57 -429 57 l-244 0 -23 -27z m576
-443 c29 -16 23 -39 -16 -56 -28 -11 -79 -14 -253 -12 l-217 3 -3 23 c-8 52
-10 52 238 52 140 0 239 -4 251 -10z"/>
<path d="M3799 5013 c-24 -32 -49 -64 -56 -71 -7 -8 -18 -22 -23 -33 -13 -23
-43 -21 265 -22 217 -2 255 -4 290 -20 79 -36 115 -87 115 -165 0 -64 -29
-108 -95 -141 -47 -25 -57 -26 -210 -26 l-160 0 -3 119 c-1 67 -7 126 -14 133
-12 15 -174 19 -196 5 -9 -7 -12 -58 -10 -223 l3 -214 265 -3 c237 -2 272 0
344 18 126 32 215 101 267 209 34 68 34 207 0 274 -31 64 -96 128 -162 161
-90 43 -173 56 -385 56 l-192 0 -43 -57z"/>
<path d="M4775 5062 c-74 -25 -75 -29 -75 -380 0 -253 3 -312 14 -321 9 -8 47
-11 107 -9 l94 3 5 184 c3 115 9 186 15 188 6 2 104 -75 219 -171 114 -96 223
-182 241 -190 42 -20 104 -20 130 0 19 14 20 30 25 346 3 216 1 336 -5 345 -8
10 -33 13 -105 11 l-95 -3 -3 -183 c-2 -149 -5 -184 -17 -188 -16 -6 -19 -4
-190 141 -183 155 -248 206 -273 216 -37 14 -66 18 -87 11z"/>
<path d="M4134 4222 c-7 -4 -35 -42 -64 -85 -137 -205 -327 -348 -570 -427
-126 -41 -230 -54 -389 -47 -242 11 -445 88 -638 244 -57 45 -103 79 -103 74
0 -5 29 -38 64 -74 304 -311 765 -431 1186 -308 294 85 551 287 696 548 20 34
33 67 29 73 -7 11 -194 14 -211 2z"/>
<path d="M2122 3119 c-24 -12 -55 -37 -70 -57 -24 -32 -27 -44 -27 -116 0 -73
3 -84 28 -118 42 -54 88 -73 178 -73 67 0 80 3 117 29 23 16 51 43 62 60 60
97 19 230 -85 277 -56 26 -148 25 -203 -2z m158 -97 c15 -12 24 -33 27 -64 5
-42 3 -50 -25 -78 -36 -35 -69 -38 -105 -10 -45 36 -44 122 1 156 25 19 76 17
102 -4z"/>
<path d="M3183 3130 c-56 -5 -59 -8 -105 -97 -6 -13 -14 -23 -17 -23 -3 0 -22
27 -41 60 l-35 60 -64 0 c-75 0 -76 7 10 -132 57 -94 59 -98 59 -167 l0 -71
65 0 65 0 0 71 c0 70 1 73 66 181 37 60 62 113 58 117 -5 3 -33 4 -61 1z"/>
<path d="M4243 3132 c-174 -3 -174 -3 -166 -59 5 -31 7 -32 57 -35 l51 -3 3
-137 3 -138 59 0 59 0 3 138 3 137 55 5 55 5 -2 39 c-2 22 -6 42 -10 45 -5 3
-81 5 -170 3z"/>
<path d="M5393 3127 c-70 -26 -122 -104 -123 -182 0 -57 37 -124 88 -160 40
-27 51 -30 125 -30 60 0 92 5 120 19 l37 18 0 85 0 84 -52 -3 -53 -3 -1 -43
c-1 -67 -48 -83 -103 -36 -26 22 -31 33 -31 70 0 79 70 117 144 79 37 -19 35
-20 75 14 31 27 33 31 20 47 -39 48 -169 69 -246 41z"/>
<path d="M1622 2948 l3 -183 115 -3 c147 -4 189 2 223 34 37 35 38 94 1 131
-21 21 -24 30 -15 42 37 52 35 94 -6 133 -23 22 -33 23 -174 26 l-149 3 2
-183z m221 83 c4 -4 5 -15 1 -24 -4 -12 -18 -17 -50 -17 -40 0 -44 2 -44 24 0
13 6 27 13 29 14 7 69 -2 80 -12z m1 -127 c9 -3 16 -17 16 -30 0 -22 -4 -24
-55 -24 -51 0 -55 2 -55 23 0 13 3 27 7 30 8 9 66 9 87 1z"/>
<path d="M2480 2945 l0 -185 120 0 c156 0 215 23 256 102 19 37 18 132 -2 171
-21 40 -32 51 -80 76 -33 18 -56 21 -166 21 l-128 0 0 -185z m235 71 c32 -21
42 -74 21 -114 -18 -35 -33 -42 -92 -42 l-44 0 0 83 c0 46 3 87 8 91 11 12 79
0 107 -18z"/>
<path d="M3270 2945 l0 -185 118 0 c141 0 178 9 225 55 89 86 67 237 -43 293
-39 19 -59 22 -172 22 l-128 0 0 -185z m217 83 c39 -18 52 -37 53 -79 0 -52
-26 -80 -83 -89 -26 -4 -49 -5 -52 -2 -3 3 -5 45 -5 94 l0 88 30 0 c16 0 42
-6 57 -12z"/>
<path d="M3790 3064 c-16 -37 -30 -68 -30 -70 0 -2 -11 -27 -24 -56 -40 -87
-76 -170 -76 -174 0 -2 28 -4 63 -4 60 0 63 1 74 29 10 28 14 29 78 33 76 3
76 3 92 -34 12 -26 17 -28 73 -28 33 0 60 4 60 10 0 8 -27 71 -90 215 -10 22
-28 64 -40 93 l-22 52 -64 0 -64 0 -30 -66z m110 -100 c14 -37 13 -41 -15 -46
-32 -7 -37 2 -24 41 13 38 26 40 39 5z"/>
<path d="M4525 3073 c-15 -32 -33 -74 -40 -93 -7 -19 -29 -70 -49 -114 -20
-43 -36 -85 -36 -92 0 -10 16 -14 59 -14 57 0 59 1 70 30 10 30 11 30 85 30
73 0 74 0 85 -30 10 -29 12 -30 75 -30 l66 0 -19 48 c-11 26 -47 108 -80 182
l-60 135 -64 3 -65 3 -27 -58z m105 -102 c18 -35 12 -51 -19 -51 -29 0 -29 1
-18 35 12 40 22 44 37 16z"/>
<path d="M4860 2945 l0 -185 60 0 60 0 0 75 c0 41 4 75 8 75 9 0 16 -8 90 -98
43 -51 45 -52 98 -52 l54 0 0 185 0 186 -62 -3 -63 -3 -2 -68 c-1 -38 -4 -71
-6 -74 -3 -2 -34 30 -68 71 l-64 76 -52 0 -53 0 0 -185z"/>
<path d="M0 40 l0 -40 3580 0 3580 0 0 40 0 40 -3580 0 -3580 0 0 -40z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
